<template>
  <b-container fluid="xs">
    <vue-element-loading :active="isloading" spinner="ring" background-color="rgba(255,255,255,0.6)" size="400" color="#FF0"/>
    <div v-show="items.length">
    <b-table-simple hover small caption-top responsive="xs">
      <b-thead head-variant="light">
        <b-tr>
          <b-th>{{$t('message.logs_evbk')}}</b-th>
          <b-th>{{$t('message.logs_happen')}}</b-th>
          <b-th>{{$t('message.logs_uidmac')}}</b-th>
          <b-th>{{$t('message.logs_oper')}}</b-th>
          <b-th>{{$t('message.logs_remoteip')}}</b-th>
          <b-th>{{$t('message.logs_desc')}}</b-th>
        </b-tr>
      </b-thead>
      <b-tbody v-for="(item, index) in items" :key="index">
        <b-tr>
          <b-td>{{item.cbk}}</b-td>
          <b-td>{{item.happen}}</b-td>
          <b-td>{{item.uid}}</b-td>
          <b-td>{{item.opstr}}</b-td>
          <b-td>{{item.remoteip}}</b-td>
          <b-td>{{item.txt}}</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    </div>
    <div v-show="items.length==0" class="pl-5 pr-5">
      <b-card class="xnshadow" header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h4 class="mb-0"><b-icon icon="people"></b-icon> system logs</h4>
        </template>
        <b-card-text>
          <p>
            no logs found<br/><br/>
           </p>
        </b-card-text>
      </b-card>      
    </div>
    <b-form inline class="mt-4 ml-4" @submit.stop.prevent>
      <b-button-group class="mr-4 mb-4">
        <b-button variant="success" @click="fetchprev" :disabled="pageid==0">{{$t('message.btn_prevpage')}}</b-button>
        <b-button v-if="pageid>0" variant="outline-success" :disabled="true" >{{pageid+1}}</b-button>
        <b-button variant="success" @click="fetchNext" :disabled="pageid==newpagestart.length">{{$t('message.btn_nextpage')}}</b-button>
      </b-button-group>
      <b-radio-group buttons v-model="human" class="mr-4 mb-4" :options="humans" @change="uchange" button-variant="outline-primary"></b-radio-group>
      <b-input-group class="mb-4">
        <b-form-input placeholder="IP" v-model="searchstr" @keyup.enter="search"></b-form-input>
        <b-input-group-append>
          <b-button variant="info" @click="search">{{$t('message.btn_search')}}</b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form>
    <b-modal v-model="modalshow" no-close-on-backdrop no-close-on-esc hide-header ok-only :ok-title="$t('message.btn_ok')">{{modalmsg}}</b-modal>
  </b-container>
</template>

<script>
  let timer = undefined;
  let reloades = 0;
  export default {
    name: 'admlogs',
    components: {
    },
    mounted() {
      this.isloading = true;
      this.human = parseInt(localStorage.getItem('human')) || 0;
      this.fetchData();
    },
    data() {
      return {
        isloading: false,
        modalshow:false,
        modalmsg: '',
        searchstr: '',
        items: [],
        pageid:0,
        human:0,
        humans: [{
          text: this.$t('message.logshuman'),
          value: 0
        }, {
          text: this.$t('message.logsdevice'),
          value: 1
        }],
        newpagestart:[]
      };
    },
    methods: {
      fetchprev() {
        this.isloading = true;
        this.pageid = this.pageid-1;
        this.fetchData();
      },
      fetchPage(page) {
        this.isloading = true;
        this.pageid = page;
        this.fetchData();
      },
      fetchNext() {
        if (timer) {
          clearTimeout(timer);
          timer = undefined;
        }
        this.isloading = true;
        this.pageid = this.pageid+1;
        this.fetchData();
      },
      uchange($event) {
        this.searchstr = '';
        this.human = $event;
        localStorage.setItem('human',''+$event);
        this.pageid = 0;
        this.newpagestart = [];
        this.fetchData();
      },
      search() {
        if (timer) {
          clearTimeout(timer);
          timer = undefined;
        }
        this.isloading = true;
        this.pageid = 0;
        this.newpagestart = [];
        this.fetchData();
      },
      async fetchData() {
        reloades++;
        let lotoken = localStorage.getItem('token');
        let w = window.innerWidth;
        let h = window.innerHeight;
        let theapi = 'token='+lotoken+'&pagesize='+this.$pagesize+'&w='+w+'&h='+h+'&human='+this.human;
        if (this.$route.query.uid) {
          theapi = theapi + '&uid='+this.$route.query.uid;
        }
        if (this.searchstr) {
          theapi = theapi + '&search='+encodeURIComponent(this.searchstr);
        }
        if (this.pageid>0 && this.newpagestart[this.pageid-1]) {
          theapi = theapi + '&last='+encodeURIComponent(JSON.stringify(this.newpagestart[this.pageid-1]));
        }
        let axresp = await this.axios.post('/admlogs?tm='+new Date().getTime(), theapi);
        this.isloading = false;
        if (axresp.status==200) {
          let axdata = axresp.data;
          this.items = axdata.Items;
          if (axdata.LastEvaluatedKey) {
            if (this.newpagestart.length<=this.pageid) {
              this.newpagestart.push(axdata.LastEvaluatedKey);
            } else {
              this.newpagestart[this.pageid] = axdata.LastEvaluatedKey;
            }
          }
        } else {
          this.items = [];
        }
        if (this.pageid==0 && this.searchstr=='' && this.$route.fullPath.indexOf('admlogs')>0 && reloades<10000) {
          timer = setTimeout(this.fetchData, 10000);
        }
      },
    }
  }
</script>
